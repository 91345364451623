// FullWidthImage.js file

import * as React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { loaded } from '../../utils/helpers';

const CustomLink = loadable(() => import('../../components/common/CustomLink'));

const HighlightedGroupOfLinks = React.memo(({ slice }) => {
  const isPC = loaded && window.innerWidth >= 992;
  const hasChild = slice?.items?.length > 0;

  return (
    <li className="nav-content__nav__item">
      {!hasChild || (hasChild && isPC) ? (
        <CustomLink
          link={slice.primary?.link}
          text={slice.primary?.group_label && slice.primary?.group_label}
          className="nav-content__nav__item__link"
        />
      ) : (
        <a className="nav-content__nav__item__link">
          {slice.primary?.group_label && slice.primary.group_label}
        </a>
      )}

      {hasChild && (
        <ul className="nav-content__sub-nav">
          <li className="nav-content__sub-nav__item d-lg-none d-block">
            <CustomLink
              link={slice.primary.link}
              text={`All ${
                slice.primary?.group_label && slice.primary?.group_label
              }`}
              className="nav-content__sub-nav__item__link"
            />
          </li>
          {slice.items
            .filter((item) => item?.category_link_label)
            .map((item, index) => (
              <li key={index} className="nav-content__sub-nav__item">
                <CustomLink
                  link={item?.category_link}
                  text={item?.category_link_label && item?.category_link_label}
                  className="nav-content__sub-nav__item__link"
                />
              </li>
            ))}
        </ul>
      )}
    </li>
  );
});

export default HighlightedGroupOfLinks;

export const query = graphql`
  fragment MegaDataBodyGroupOfLinks1 on PrismicMegaDataBodyGroupOfLinks1 {
    primary {
      group_label
      link {
        url
        uid
        type
        link_type
        document {
          ... on PrismicCategory {
            url
          }
          ... on PrismicBrand {
            url
          }
        }
      }
    }
    items {
      category_link_label
      category_link {
        url
        uid
        type
        link_type
        document {
          ... on PrismicCategory {
            url
          }
          ... on PrismicBrand {
            url
          }
        }
      }
    }
  }
`;
